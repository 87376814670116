import "./WindyCams.css"
import "leaflet-velocity-ts";
import "leaflet";
import { forwardRef, useEffect } from "react";
import { useLeafletContext } from "@react-leaflet/core";
import { useMap } from "react-leaflet";
declare let L: any;


const WindyLayer = forwardRef((props: any, ref) => {
  const map: any = useMap();
  const context = useLeafletContext();

  const velocityLayer = new L.velocityLayer({
    displayValues: props.displayValues,
    displayOptions: props.displayOptions,
    data: props.data[0].collection,
    minVelocity: props.minVelocity,
    maxVelocity: props.maxVelocity,
    opacity: props.opacity,
    colorScale: props.colorScale
  });

  useEffect(() => {
    const container = context.layerContainer || context.map;
    container.addLayer(velocityLayer);
    // setTimeout( () => {
    //   map?.timeDimension.on('timeload', (data: any) => {
    //     const timeIndex = data.target._currentTimeIndex;
    //     const wind = props.data[timeIndex].collection;
    //     if (timeIndex % 4 === 3) {
    //       updateLayer(wind);
    //     }
    //   });
    // }, 500);
    map?.timeDimension.on('timeload', (data: any) => {
      const timeIndex = data.target._currentTimeIndex;
      const wind = props.data[timeIndex]?.collection;
      if (wind) {
        updateLayer(wind);
      }
    });
    return () => {
      container.removeLayer(velocityLayer);
    };
  }, []);

  function updateLayer(data: any) {
    if (data && data.length) {
      velocityLayer.setData(data);
    }
  }

  return null;
});

export default WindyLayer;
