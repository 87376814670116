import { configureStore } from '@reduxjs/toolkit';
import inciSlice from './Reducers/inciSlice';
import snackMsgSlice from './Reducers/snackbarMessager';

export const store = configureStore({
  reducer: {
    inci: inciSlice,
    snack: snackMsgSlice
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch