import { ReactElement, useEffect, useRef } from "react";
import { GeoJSON, GeoJSONProps, useMap } from "react-leaflet";
import { GeoJSON as LeafletGeoJSON, LatLngBounds } from "leaflet";
import mapPredictionsService from "../Services/map-predictions.service";

export default function WindyCams(props: GeoJSONProps): ReactElement {
  const geoJsonLayerRef = useRef<LeafletGeoJSON | null>(null);
  const map = useMap();

  useEffect(() => {
    map.on('zoomend',function(e) {
      updateLayer(e.target.getBounds(), e.target.getZoom());
    });
    map.on('moveend',function(e) {
      updateLayer(e.target.getBounds(), e.target.getZoom());
    });
  }, [props.data, props.pathOptions, props.style]);

  function updateLayer(bounds: LatLngBounds, zoom: number) {
    mapPredictionsService.getWindyCams(bounds.getNorth(), bounds.getEast(), bounds.getSouth(), bounds.getWest(), zoom)
    .then(res => {
      const windyCamsGeojson = res.data.data;
      const layer = geoJsonLayerRef?.current;
      if (layer) {
        layer.clearLayers().addData(windyCamsGeojson);
      }
    })
    .catch(error => {
      console.error(error);
    });
  }

  return <GeoJSON
    {...props}
    ref={geoJsonLayerRef} />;

}