import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL || 'http://maps-api.firemap.org/api/';
const API_URL = `${baseURL}auth/`;
class AuthService {
  login(email: string, password: string) {
    return axios
      .post(API_URL + "login", {
        email,
        password
      })
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem("user-data", JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  logout() {
    localStorage.removeItem("user-data");
  }
  register(username: string, email: string, password: string, password_confirmation: string) {
    return axios.post(API_URL + "register", {
      username,
      email,
      password,
      password_confirmation
    });
  }
  getCurrentUserData() {
    const userStr = localStorage.getItem("user-data");
    if (userStr) return JSON.parse(userStr);
    return null;
  }
}
export default new AuthService();