import { Alert, AlertColor, Snackbar } from '@mui/material';
import { Component } from 'react';
import './App.css';
import { IUserData } from './Common/interfaces';
import { Firemap } from './Components/Firemap';
import LoadingSpinner from './Components/Loader';
import AuthService from './Services/auth.service';
type Props = {};
type State = {
  showMap: boolean,
  currentUserData: IUserData | undefined,
  snackbarOpen: boolean,
  snackbarMessage: string,
  alertSeverity: AlertColor
}

class App extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      currentUserData: undefined,
      showMap: false,
      snackbarOpen: false,
      snackbarMessage: 'Somthing wrong!',
      alertSeverity: 'error',
    };
  }
  componentDidMount() {
    const userData = AuthService.getCurrentUserData();
    if (userData) {
      this.setState({
        currentUserData: userData,
        showMap: true,
      });
    } else {
      AuthService.login('vkovtunov@intetics.com', 'password').then(
        response => {
          this.setState({
            currentUserData: response.data,
            showMap: true,
          });
        },
        error => {
          console.error(error);
          this.setState({
            showMap: true,
          });
          this.snackbarOpen(error.message);
        }
      );
    }
  }

  snackbarOpen(message = 'Somthing wrong!', severity: AlertColor = 'error') {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
      alertSeverity: severity
    });
  }

  snackbarClose(event?: React.SyntheticEvent | Event, reason?: string) {
    this.setState({snackbarOpen: false});
  }

  render() {
    if(this.state.showMap) {
      return (
        <div className="App">
          <Firemap></Firemap>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={6000}
            open={this.state.snackbarOpen}
            onClose={this.snackbarClose.bind(this)}
            message={this.state.snackbarMessage}
            key={'login'} >
            <Alert onClose={this.snackbarClose.bind(this)} severity={this.state.alertSeverity} sx={{ width: '100%' }}>
              {this.state.snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      );
    } else {
      return (
        <LoadingSpinner loadingText='Loading content from NASA, NOAA, US Forest Service, and local data partners . . .' />
      );
    }
  }
}

export default App;
