import "./WindyCamsVideo.css";
import { ReactElement } from "react";


export default function WindyCamsVideo(props: any): ReactElement {
  return (
    <div className="WindyCam">
      <div className="WindyCam-item">Name: <span>{props.name}</span></div>
      <div className="WindyCam-item">Country: <span>{props.country}</span></div>
      <div className="WindyCam-item">Region: <span>{props.region}</span></div>
      <div className="WindyCam-item">City: <span>{props.city}</span></div>
      <div className="WindyCam-item">Status: <span>{props.status}</span></div>
      <div className="WindyCam-item">Preview updated: <span>{props.previewUpdated}</span></div>
      <div className="WindyCam-video">
        <embed src={props.camUrl} type="video/webm" width="100%" height="500" />
      </div>
    </div>
  );
}