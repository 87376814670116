import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../Store';

// Define a type for the slice state
interface SnackbarMessager {
  showSnackbar: boolean,
  message: string
}

const initialState: SnackbarMessager = {
  showSnackbar: false,
  message: 'Something broke!'
}

export const snackMsgSlice = createSlice({
  name: 'snackMsg',
  initialState,
  reducers: {
    showSnack: (state, action: PayloadAction<string>) => {
      state.showSnackbar = true;
      state.message = action.payload;
      console.log('open!!!!');
      console.log(action.payload);
      
    },
  },
});

export const { showSnack } = snackMsgSlice.actions;


export const snackbarMessage = (state: RootState) => state.snack.message;
export const showSnackbar = (state: RootState) => state.snack.showSnackbar;

export default snackMsgSlice.reducer;
